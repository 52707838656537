import { Directive } from '@angular/core';

@Directive({
  selector: '[ngx-hm-carousel-item]'
})
export class NgxHmCarouselItemDirective {

  constructor() { }

}
